<template>
  <div
    class="Auth row row--justify-center"
    data-cy="login"
  >
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!resetRequired"
        class="Auth__inputs column column--width-auto"
        key="Auth"
      >
        <label
          v-if="false"
          class="Auth__alert-notification bg-yellow fc-dark fs-15"
        >
          <!-- ENTER ALERT MESSAGE HERE -->
        </label>
        <img
          alt="Bobtail logo of the word 'Bobtail'"
          class="Auth__signin-logo mb-30"
          src="../../assets/logo.png"
        >
        <label class="fs-18 fw-med mb-18">Sign In</label>
        <base-input
          v-model.trim="email"
          class="mb-12 width-100"
          data-cy="login-email"
          :instructions="instructions.EMAIL_INSTRUCTION"
          :label="true"
          :label-for="'email'"
          :placeholder="'Email'"
          ref="Auth__email-input"
          :type="'email'"
          :valid="emailValid"
        >
          Sign in with your e-mail address
        </base-input>
        <base-input
          v-model.trim="password"
          class="width-100"
          data-cy="login-password"
          :instructions="instructions.PASSWORD_INSTRUCTION"
          :label="true"
          :label-for="'password'"
          :placeholder="'Password'"
          :type="'password'"
          :valid="passwordValid"
        >
          Password
        </base-input>

        <base-button
          @click="validation"
          @keydown.enter="validation"
          class="bg-blue fc-white mt-16 width-100"
        >
          Sign In
        </base-button>

        <hr class="Divider mb-14 mt-29">

        <div class="row row--justify-end">
          <router-link
            class="fc-light fs-14 mb-12"
            tag="a"
            :to="{ name: 'password-reset' }"
          >
            Forgot password
          </router-link>
        </div>
      </div>

      <!-- reset required -->
      <div
        v-else
        class="Auth__inputs column"
        key="reset"
      >
        <img
          alt="Bobtail logo of the word 'Bobtail'"
          class="Auth__signin-logo mb-30"
          src="../../assets/logo.png"
        >
        <label class="fs-18 fw-med mb-18">Reset Password</label>
        <base-input
          v-model.trim="newPassword"
          class="mb-12 width-100"
          :instructions="instructions.PASSWORD_INSTRUCTION"
          :label="true"
          :label-for="'new-password'"
          :placeholder="'Password'"
          :type="'password'"
          :valid="newPasswordValid"
        >
          Password
        </base-input>
        <base-input
          v-model.trim="newPasswordVerification"
          class="mb-12 width-100"
          :instructions="instructions.PASSWORD_VERIFICATION_INSTRUCTION"
          :label="true"
          :label-for="'password-verification'"
          :placeholder="'Confirm Password'"
          :type="'password'"
          :valid="newPasswordVerificationValid"
        >
          Password Verification
        </base-input>
        <base-button
          @click="resetPassword"
          @keydown.enter="resetPassword"
          class="bg-blue fc-white width-100"
          :disabled="buttonDisabled"
        >
          Set Password
        </base-button>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
import validator from 'validator'
// Helpers
import {
  getCurrentJWTToken,
  globalSignOut,
} from '../../utils/cognito'
import { storeUserInLocalStorage } from '../../utils/helpers'
import getCognitoInfo from '../../utils/constants';
// Components
import BaseButton from '../../components/base-button.vue'
import BaseInput from '../../components/base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'

const { UserPoolId, ClientId } = getCognitoInfo()

export default {
  name: 'Login',

  components: {
    BaseButton,
    BaseInput,
  },

  mixins: [ValidationMixin],

  async created () {
    if (await getCurrentJWTToken()) {
      globalSignOut()
    }
  },

  mounted () {
    window.addEventListener('keydown', this.enterBtnEventListener)

    // Autofocus email input
    // Must wait for input to fully render
    this.$nextTick(() => {
      this.$refs['Auth__email-input'].$el.children[0].focus()
    })
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.enterBtnEventListener)
  },

  data () {
    const isProd = process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal'

    return {
      buttonDisabled: false,
      email: isProd ? '' : 'testbobtail+johnsocttest3@gmail.com',
      emailValid: true,
      password: isProd ? '' : 'BOBtail1!',
      passwordValid: true,
      resetRequired: false,
      newPassword: isProd ? '' : 'BOBtail1!',
      newPasswordValid: true,
      newPasswordVerification: isProd ? '' : 'BOBtail1!',
      newPasswordVerificationValid: isProd ? '' : 'BOBtail1!',
      cognitoUser: null,
      userAttributes: null,
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    enterBtnEventListener (e) {
      if (e.keyCode === 13) {
        this.validation()
      }
    },

    async login () {
      this.buttonDisabled = true
      this.progressStart()

      if (this.email) this.email = this.email.toLowerCase()

      const authData = {
        Username: this.email && this.email.toLowerCase,
        Password: this.password,
      }
      const authDetails = new AuthenticationDetails(authData)
      const poolData = {
        UserPoolId,
        ClientId,
      }
      const thisComponent = this
      const userPool = new CognitoUserPool(poolData)
      const userData = {
        Username: this.email,
        Pool: userPool,
      }
      const cognitoUser = new CognitoUser(userData)
      this.cognitoUser = cognitoUser
      cognitoUser.authenticateUser(authDetails, {

        newPasswordRequired (userAttributes) {
          thisComponent.buttonDisabled = false;
          thisComponent.progressFinish();
          thisComponent.resetRequired = true;
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          thisComponent.userAttributes = userAttributes;
        },

        onFailure (error) {
          thisComponent.CError(error)
          // User not found
          thisComponent.$Progress.fail()
          thisComponent.$Toastr.error(error.message, 'Log in')
          thisComponent.buttonDisabled = false
        },

        async onSuccess () {
          try {
            // navUrl is where the user will be redirected on success
            let navUrl = null
            if (thisComponent.$route
                && thisComponent.$route.params
                && thisComponent.$route.params.previous
            ) {
              navUrl = thisComponent.$route.params.previous.split(window.location.origin)[1]
            }
            // On mobile, we're always going to direct login to the mobile home page
            if (thisComponent.mobile.isMobile) {
              navUrl = { name: 'home' }
            }

            // Always route the user to admin if they're logging in from admin-login
            if (window.location.href.includes('admin-login')) {
              navUrl = { name: 'admin' }
            }

            try {
              await storeUserInLocalStorage()
            }
            catch (error) {
              thisComponent.$router.push({ name: 'logout' })
              thisComponent.alertError('User not found in database')
            }

            // Check if browser supports Vue-Router
            if (thisComponent.$route) {
              // Check if user came from an existing route in router.js
              // i.e. they're credentials timed out, etc. and they reloaded or went to X route
              // which took them to /login due to refresh token being expired
              if (navUrl && navUrl !== '/') {
                thisComponent.CLog(
                  '%c Browser supports vue-router and user came from a different route',
                  'color: green'
                )
                thisComponent.progressFinish()
                // Send the user BACK to the route they came from
                thisComponent.$router.push(navUrl, () => {
                  thisComponent.$root.$emit('login')
                })
              } else {
                thisComponent.CLog(
                  '%c Browser supports vue-router and user DID NOT come from a different route',
                  'color: green'
                )
                thisComponent.progressFinish()
                // Default route sent to on /login if they didn't come from an existing route
                thisComponent.$router.push({ name: 'home' }, () => {
                  thisComponent.$root.$emit('login')
                })
                thisComponent.buttonDisabled = false
              }
            } else {
              // Old browser fallback
              thisComponent.CLog('%c Browser does not support vue-router', 'color: red')
              thisComponent.$root.$emit('login')
              window.open('/home')
            }
          } catch (error) {
            thisComponent.CError(error)
            thisComponent.$Progress.fail()
            thisComponent.$Toastr.error('There was an issue storing your information')
            thisComponent.buttonDisabled = false
          }
        },
      })
    },

    async resetPassword() {
      if (!this.resetValidation()) return

      this.buttonDisabled = true
      this.progressStart()

      const thisComponent = this
      this.cognitoUser.completeNewPasswordChallenge(this.newPassword, this.userAttributes,
        {
          onSuccess () {
            thisComponent.progressFinish()
            thisComponent.requestSuccess({
              message: 'Success! You can now login normally',
              title: 'Password Set'
            })
            thisComponent.buttonDisabled = false
            thisComponent.resetRequired = false
          },
          onFailure (error) {
            thisComponent.CLog('Error:', error)
            thisComponent.buttonDisabled = false
            thisComponent.requestFailure({ message: 'There was an issue changing your password' })
          }
        })
    },

    resetValidation() {
      let valid = true

      if (!this.isValidPassword(this.newPassword)) {
        this.alertError(`${this.PASSWORD_INSTRUCTION}`, 'Input validation')
        this.newPasswordValid = false
        valid = false
      }
      else {
        this.newPasswordValid = true
      }

      if (this.newPasswordVerification !== this.newPassword) {
        this.alertError(`${this.PASSWORD_VERIFICATION_INSTRUCTION}`, 'Input validation')
        this.newPasswordVerificationValid = false
        valid = false
      }
      else {
        this.newPasswordVerificationValid = true
      }

      return valid
    },

    validation () {
      let valid = true

      if (this.email && !validator.isEmail(this.email)) {
        this.emailValid = false
        valid = false
      } else {
        this.emailValid = true
      }

      if (this.password && !this.isValidPassword(this.password)) {
        this.passwordValid = false
        valid = false
      } else {
        this.passwordValid = true
      }

      if (valid) {
        this.login()
      }
    },
  }
}
</script>

<style lang="sass">
@import '../../styles/auth.sass'


</style>