var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Auth row row--justify-center",
      attrs: { "data-cy": "login" }
    },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.resetRequired
            ? _c(
                "div",
                {
                  key: "Auth",
                  staticClass: "Auth__inputs column column--width-auto"
                },
                [
                  false
                    ? _c("label", {
                        staticClass:
                          "Auth__alert-notification bg-yellow fc-dark fs-15"
                      })
                    : _vm._e(),
                  _c("img", {
                    staticClass: "Auth__signin-logo mb-30",
                    attrs: {
                      alt: "Bobtail logo of the word 'Bobtail'",
                      src: require("../../assets/logo.png")
                    }
                  }),
                  _c("label", { staticClass: "fs-18 fw-med mb-18" }, [
                    _vm._v("Sign In")
                  ]),
                  _c(
                    "base-input",
                    {
                      ref: "Auth__email-input",
                      staticClass: "mb-12 width-100",
                      attrs: {
                        "data-cy": "login-email",
                        instructions: _vm.instructions.EMAIL_INSTRUCTION,
                        label: true,
                        "label-for": "email",
                        placeholder: "Email",
                        type: "email",
                        valid: _vm.emailValid
                      },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "email"
                      }
                    },
                    [
                      _vm._v(
                        "\n        Sign in with your e-mail address\n      "
                      )
                    ]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "width-100",
                      attrs: {
                        "data-cy": "login-password",
                        instructions: _vm.instructions.PASSWORD_INSTRUCTION,
                        label: true,
                        "label-for": "password",
                        placeholder: "Password",
                        type: "password",
                        valid: _vm.passwordValid
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "password"
                      }
                    },
                    [_vm._v("\n        Password\n      ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "bg-blue fc-white mt-16 width-100",
                      on: {
                        click: _vm.validation,
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.validation($event)
                        }
                      }
                    },
                    [_vm._v("\n        Sign In\n      ")]
                  ),
                  _c("hr", { staticClass: "Divider mb-14 mt-29" }),
                  _c(
                    "div",
                    { staticClass: "row row--justify-end" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "fc-light fs-14 mb-12",
                          attrs: { tag: "a", to: { name: "password-reset" } }
                        },
                        [_vm._v("\n          Forgot password\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { key: "reset", staticClass: "Auth__inputs column" },
                [
                  _c("img", {
                    staticClass: "Auth__signin-logo mb-30",
                    attrs: {
                      alt: "Bobtail logo of the word 'Bobtail'",
                      src: require("../../assets/logo.png")
                    }
                  }),
                  _c("label", { staticClass: "fs-18 fw-med mb-18" }, [
                    _vm._v("Reset Password")
                  ]),
                  _c(
                    "base-input",
                    {
                      staticClass: "mb-12 width-100",
                      attrs: {
                        instructions: _vm.instructions.PASSWORD_INSTRUCTION,
                        label: true,
                        "label-for": "new-password",
                        placeholder: "Password",
                        type: "password",
                        valid: _vm.newPasswordValid
                      },
                      model: {
                        value: _vm.newPassword,
                        callback: function($$v) {
                          _vm.newPassword =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "newPassword"
                      }
                    },
                    [_vm._v("\n        Password\n      ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mb-12 width-100",
                      attrs: {
                        instructions:
                          _vm.instructions.PASSWORD_VERIFICATION_INSTRUCTION,
                        label: true,
                        "label-for": "password-verification",
                        placeholder: "Confirm Password",
                        type: "password",
                        valid: _vm.newPasswordVerificationValid
                      },
                      model: {
                        value: _vm.newPasswordVerification,
                        callback: function($$v) {
                          _vm.newPasswordVerification =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "newPasswordVerification"
                      }
                    },
                    [_vm._v("\n        Password Verification\n      ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "bg-blue fc-white width-100",
                      attrs: { disabled: _vm.buttonDisabled },
                      on: {
                        click: _vm.resetPassword,
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.resetPassword($event)
                        }
                      }
                    },
                    [_vm._v("\n        Set Password\n      ")]
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }